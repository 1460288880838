<template>
    <div class="windows-dialog">

        <el-dialog :close-on-click-modal="false" title="新建人员信息" width="500px" :visible.sync="show" class="selfInputBox">

            <div class="headerBtnBox buttons">
                <el-button type="primary" @click="close">保存</el-button>
                <el-button @click="close">取消</el-button>
            </div>

            <div class="dialog-content">

                <!-- <el-tabs tab-position="left">
                    <el-tab-pane label="基本信息" disabled @click="move()">
                        <div class="dialog-box">
                            基本信息
                            <a href="https://www.baidu.com/">https://www.baidu.com/</a>
                        </div>
                        <div class="dialog-box">
                            信息设置
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="信息设置" disabled @click="move()">
                        <div class="dialog-box">
                            
                        </div>
                    </el-tab-pane>
                </el-tabs> -->


                <div class="dialog-title">
                    <a>基本信息</a>
                    <a>信息设置</a>
                </div>


                <div class="dialog-box">
                    <!-- 基本信息 -->
                    <!-- <a href="https://www.baidu.com/">https://www.baidu.com/</a> -->
                    <el-form ref="form">

                        <el-form-item label="医废类型图片" prop="name3" style="width: 100%;">

                            <el-upload drag class="SelfUploadBox" action :auto-upload="false" list-type="picture-card"
                                :on-preview="handlePreview" :on-remove="handlePreview" :before-remove="beforeRemove"
                                :on-change="uploadSectionFile" :limit="1" ref="businessLicense">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将文件拖拽到此处或点击上传</div>
                            </el-upload>

                        </el-form-item>

                        <el-form-item label="真实姓名" prop="title">
                            <el-input v-model="value.title" />
                        </el-form-item>
                        <el-form-item label="手机号码" prop="title">
                            <el-input v-model="value.title" />
                        </el-form-item>

                        <el-form-item label="科室">
                            <el-select v-model="value.region" placeholder="请选择活动区域">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="岗位">
                            <el-select v-model="value.region" placeholder="请选择活动区域">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="所属医院">
                            <el-select v-model="value.region" placeholder="请选择活动区域">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="所属卫健委">
                            <el-select v-model="value.region" placeholder="请选择活动区域">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>


                        <el-form-item label="所属城市" style="width: 100%;">
                            <el-select v-model="value.region" placeholder="省">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                            <el-select v-model="value.region" placeholder="市">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                            <el-select v-model="value.region" placeholder="县">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>


                        <el-form-item label="地址" style="width: 50%;">
                            <el-input v-model="value.address" />
                        </el-form-item>

                        <el-form-item label="管理员" prop="name2" style="clear: both;">
                            <el-switch v-model="state" active-color="#5BD995" active-text="是" inactive-color="#D9D9D9"
                                inactive-text="否" class="switchSelfBox">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="状态" prop="name2" style="clear: both;">
                            <el-switch v-model="state" active-color="#5BD995" active-text="正常" inactive-color="#D9D9D9"
                                inactive-text="禁用" class="switchSelfBox">
                            </el-switch>
                        </el-form-item>

                    </el-form>

                    <h3 class="minddle-title" style="">用户信息设置</h3>

                    <div class="dialog-cell">

                        <el-form ref="form1">


                            <el-form-item label="app权限角色" class="longcell">
                                <el-checkbox-group v-model="value.type">
                                    <el-checkbox label="收集人" name="type"></el-checkbox>
                                    <el-checkbox label="入库人" name="type"></el-checkbox>
                                    <el-checkbox label="出库人" name="type"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>


                            <el-form-item label="用户名" prop="title">
                                <el-input v-model="value.title" />
                            </el-form-item>
                            <el-form-item label="密码" prop="title">
                                <el-input v-model="value.title" />
                            </el-form-item>

                            <el-form-item label="封箱签名数量" prop="title">
                                <el-input v-model="value.title" />
                            </el-form-item>
                            <el-form-item label="入库签名数量" prop="title">
                                <el-input v-model="value.title" />
                            </el-form-item>


                            <el-form-item label="功能模块" class="longcell">
                                <el-checkbox-group v-model="value.type">
                                    <el-checkbox label="PDA" name="type"></el-checkbox>
                                    <el-checkbox label="微信小程序" name="type"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>

                            <el-form-item label="后台所属角色" class="longcell">
                                <el-checkbox-group v-model="value.type">
                                    <el-checkbox label="管理员" name="type"></el-checkbox>
                                    <el-checkbox label="双街镇社区卫生服务中心" name="type"></el-checkbox>
                                    <el-checkbox label="和平区南营门街社区卫生服务中心" name="type"></el-checkbox>
                                    <el-checkbox label="天津和平区卫健委" name="type"></el-checkbox>
                                    <el-checkbox label="天津北辰区卫健委" name="type"></el-checkbox>
                                    <el-checkbox label="河东区大王庄街社区卫生服务中心" name="type"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>





                        </el-form>



                        <div style="clear: both;"> </div>
                    </div>








                </div>

            </div>



            <!-- <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="close">保存</el-button>
                <el-button @click="$refs.form.resetFields()">重置</el-button>
            </div>
        </span> -->





        </el-dialog>

    </div>
</template>

<script>
    export default {

        data() {
            return {
                show: false,

                state: true,

                // 回显图片的路径
                photoList: [{
                    url: ""
                }],
                // 图片的路径
                dialogImageUrl: "",


                value: {
                    title: "",
                    name: "",
                    region: "",
                    address: "",
                    type: "",
                }

            }
        },
        methods: {
            open() {
                // this.formShow = true
                this.show = true
            },
            close() {
                this.show = false
                // this.$emit('refresh')
                // setTimeout(() => {
                //     this.formShow = false
                // }, 1000)
            },

            uploadSectionFile(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("111111" + file.url);
            },



            beforeRemove(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("22222" + file.url);
            },

            handlePreview(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("33333" + file.url);
            },

            move() {

                console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~");


            },




        }
    }
</script>


<style scoped>
    /deep/.el-tabs__header {
        background-color: #fff;
    }

    /deep/.el-tabs__item.is-disabled {
        color: #768dfc;
        cursor: default;
    }
</style>